<template>
  <v-row>
    <v-col cols="12">
      <div>
        <label for="contract-party-roles"> Role </label>
        <v-chip-group
          id="contract-party-roles"
          v-model="caseRole.roles"
          column
          multiple
          selected-class="bg-primary"
          :class="{
            'error-border rounded': rolesValidation.errorMessages.length
          }"
        >
          <v-chip
            v-for="role in visibleRoles"
            :key="role.value"
            :data-testid="`role-${role.title}`"
            :value="role.value"
            :text="role.title"
          />
          <a v-if="truncatedRoles" class="mt-3" @click="showMore = !showMore">
            {{ showMore ? "Show Less" : "Show More" }}
          </a>
        </v-chip-group>
        <div
          v-if="rolesValidation.errorMessages.length"
          class="v-messages text-error opacity-100"
        >
          <div class="v-messages__wrapper">
            <div class="v-messages__message mx-3">
              {{ rolesValidation.errorMessages[0] }}
            </div>
          </div>
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <autocomplete-field
        v-if="displayRelationship"
        v-model="caseRole.relationship"
        data-testid="relationship"
        :prepend-inner-icon="mdiBriefcase"
        label="Relationship"
        :items="relationships"
        :success="relationshipValidation.success"
        :error-messages="relationshipValidation.errorMessages"
        clearable
      />
    </v-col>

    <v-col cols="12">
      <decimal-input
        v-if="displayBeneficiaryQualifier"
        v-model="caseRole.beneficiaryAmount"
        data-testid="beneficiary-amount"
        data-lpignore="true"
        :prepend-inner-icon="mdiPercent"
        label="Beneficiary Percentage"
        type="text"
        inputmode="numeric"
        :min="0"
        :max="100"
        :decimal-length="2"
        :success="beneficiaryAmountValidation.success"
        :error-messages="beneficiaryAmountValidation.errorMessages"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import DecimalInput from "@/components/shared/DecimalInput.vue";
import {
  ENTITY_RELATIONSHIPS,
  INDIVIDUAL_RELATIONSHIPS,
  MUTUALLY_EXCLUSIVE_ROLES,
  ROLES_BY_LINE
} from "@/models/Role";

import { computed, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { computedValidation, listToSentence } from "@/util/helpers";

import { mdiBriefcase, mdiPercent } from "@mdi/js";
import { useDisplay } from "vuetify";

const emit = defineEmits([
  "update:roles",
  "update:beneficiaryAmount",
  "update:relationship"
]);

const props = defineProps({
  roles: { type: Array, required: false, default: () => null },
  beneficiaryAmount: { type: Number, required: false, default: null },
  relationship: { type: String, required: false, default: null },
  line: { type: String, required: true },
  type: {
    type: String,
    required: true,
    validates: v => ["Individual", "Entity"].includes(v)
  },
  validationScope: { type: String, required: true },
  allowDeletion: Boolean
});

const caseRole = ref({
  roles: props.roles,
  beneficiaryAmount: props.beneficiaryAmount,
  relationship: props.relationship
});

const showMore = ref(false);

const displayBeneficiaryQualifier = computed(() =>
  caseRole.value.roles.some(val => val.includes("Beneficiary"))
);
const displayRelationship = computed(
  () =>
    caseRole.value.roles.length &&
    caseRole.value.roles.every(
      r => !r.includes("Insured") && !r.includes("Annuitant")
    )
);

const topRoles = computed(() => {
  if (!ROLES_BY_LINE[props.line]) return [];
  return ROLES_BY_LINE[props.line].slice(0, 5);
});

const remainingRoles = computed(() => {
  if (!ROLES_BY_LINE[props.line]) return [];
  return ROLES_BY_LINE[props.line].filter(v => !topRoles.value.includes(v));
});

const { smAndDown } = useDisplay();
const truncatedRoles = computed(() => smAndDown.value);

const visibleRoles = computed(() => {
  let roles = [...topRoles.value];

  if (showMore.value || !truncatedRoles.value) {
    roles.push(...remainingRoles.value);
  }

  return roles.map(r => ({ title: r, value: r }));
});

const relationships = computed(() => {
  if (!props.type) return [];
  if (props.type === "Entity") return ENTITY_RELATIONSHIPS;

  return INDIVIDUAL_RELATIONSHIPS;
});

const invalidRolesText = computed(() => {
  const invalidRoles = getRolesFailingMutualExclusion(caseRole.value.roles);

  let text = "";
  for (const r of invalidRoles) {
    text += `${r.role} is mutually exclusive with ${listToSentence(r.mutuallyExclusiveWith)}. `;
  }

  return text;
});

const v$ = useVuelidate(
  {
    caseRole: {
      relationship: {
        required: v => !displayRelationship.value || Boolean(v)
      },
      beneficiaryAmount: {
        required: v => !displayBeneficiaryQualifier.value || (v > 0 && v <= 100)
      },
      roles: {
        required: r => props.allowDeletion || r.length > 0,
        noMutualExclusion: r => getRolesFailingMutualExclusion(r).length === 0
      }
    }
  },
  {
    caseRole
  },
  { $scope: props.validationScope, $autoDirty: true }
);

function getRolesFailingMutualExclusion(roles) {
  const invalidRolesStruct = [];

  for (const role of roles) {
    if (!MUTUALLY_EXCLUSIVE_ROLES[role]) continue;

    if (MUTUALLY_EXCLUSIVE_ROLES[role].some(mrole => roles.includes(mrole))) {
      invalidRolesStruct.push({
        role,
        mutuallyExclusiveWith: MUTUALLY_EXCLUSIVE_ROLES[role]
      });
    }
  }

  return invalidRolesStruct;
}

const relationshipValidation = computedValidation(
  v$.value.caseRole.relationship,
  {
    required: "Required"
  }
);
const beneficiaryAmountValidation = computedValidation(
  v$.value.caseRole.beneficiaryAmount,
  { required: "Required" }
);

const rolesValidation = computedValidation(v$.value.caseRole.roles, {
  required: "Required",
  noMutualExclusion: invalidRolesText
});

watch(
  () => caseRole.value.roles,
  () => emit("update:roles", caseRole.value.roles)
);

watch(
  () => caseRole.value.beneficiaryAmount,
  () => emit("update:beneficiaryAmount", caseRole.value.beneficiaryAmount)
);

watch(
  () => caseRole.value.relationship,
  () => emit("update:relationship", caseRole.value.relationship)
);
</script>
