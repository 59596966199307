<template>
  <v-card flat color="section">
    <v-card-title>Quote & Apply</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <approved-domain-search
            v-model="defaultApprovedDomain"
            label="Default Quote & Apply Website"
            data-testid="agent-profile-default-website"
            :prepend-inner-icon="mdiSitemap"
            :agent-id="agent.id"
            :success="Boolean(defaultApprovedDomain)"
            @update:model-value="updateDefaultApprovedDomain"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="
                  savingBuffer.defaultApprovedDomain.controller.value
                "
              />
            </template>
          </approved-domain-search>
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="schedulingLink"
            data-lpignore="true"
            label="Calendar Scheduling Link"
            :prepend-inner-icon="mdiCalendar"
            data-testid="agent-profile-scheduling-link"
            prefix="https://"
            :success="schedulingLinkValidation.success"
            :error-messages="schedulingLinkValidation.errorMessages"
            persistent-hint
            hint=" "
            clearable
            @update:model-value="saveSchedulingLink"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.schedulingLink.controller.value"
              />
            </template>
            <template #message="{ message }">
              <v-row class="ma-0">
                <template v-if="message.length > 1">{{ message }}</template>
                <div v-else>
                  Add a calendar scheduling link (e.g. Calendly) to be used on
                  your Quote & Apply websites.
                  <a href="https://calendly.com/" target="_blank">
                    Click here to sign up for Calendly.</a
                  >
                </div>
              </v-row>
            </template>
          </text-field>
        </v-col>
        <v-col cols="12" md="6">
          <div class="checkbox-width">
            <checkbox-field
              v-model="messageClient"
              label="Message Client"
              dense
              class="mt-1"
              hide-details
              data-testid="message-client"
              :success="Boolean(messageClient)"
              readonly
              @click="toggleMessageClient"
            />
          </div>
          <div>
            Automatically send email and text message notifications when your
            case status updates to Submitted, Approved, Active, or when
            Conversion offers become available.
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ApprovedDomainSearch from "@/components/shared/ApprovedDomainSearch.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { useActiveSave } from "@/composables/active-save.composable";
import { computedValidation } from "@/util/helpers";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { useDialogStore } from "@/stores/dialog";
import { storeToRefs } from "pinia";
import { url } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mdiSitemap, mdiCalendar } from "@mdi/js";
import { markRaw } from "vue";

const agent = useAgentSettingsStore();
const dialog = useDialogStore();

const { schedulingLink, defaultApprovedDomain, messageClient } =
  storeToRefs(agent);

const savingBuffer = {
  defaultApprovedDomain: useActiveSave(),
  schedulingLink: useActiveSave()
};

const v$ = useVuelidate(
  {
    schedulingLink: {
      validUrl(value) {
        if (!value) return true;
        if (value.includes("http://") || value.includes("https://"))
          return false;
        return url.$validator(`https://${value}`);
      }
    }
  },
  { schedulingLink },
  { $autoDirty: true, $scope: false }
);

function updateDefaultApprovedDomain() {
  savingBuffer.defaultApprovedDomain.update(agent.updateDefaultApprovedDomain);
}

function toggleMessageClient() {
  const proposedValue = !messageClient.value;

  const title = `${proposedValue ? "Enable" : "Disable"} Message Client`;
  const subtitle = `${proposedValue ? "Automatically" : "Do not automatically"} send email and text message notifications when your case status updates to Submitted, Approved, Active, or when Conversion offers become available.`;

  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title,
    subtitle,
    checkboxText: "Apply to all past cases?",
    func: ({ checkboxChecked }) => {
      let applyToAllPastCases = null;
      if (checkboxChecked) applyToAllPastCases = proposedValue;
      return agent.updateMessageClient(proposedValue, applyToAllPastCases);
    }
  });
}

function saveSchedulingLink() {
  savingBuffer.schedulingLink.debounceUpdate(async () => {
    const isValid = await v$.value.schedulingLink.$validate();
    if (!isValid) return;
    return agent.updateSchedulingLink();
  });
}

const schedulingLinkValidation = computedValidation(v$.value.schedulingLink, {
  validUrl: "Invalid Url"
});
</script>
